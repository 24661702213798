export const emotions = {
    regret: {label: 'Regret', color: '#008FFB'},
    hate: {label: 'Haine', color: '#FF0000'},
    distress: {label: 'Détresse', color: '#FEB019'},
    fear: {label: 'Peur', color: '#FF4560'},
    sadness: {label: 'Tristesse', color: '#775DD0'},
    shame: {label: 'Honte', color: '#3f51b5'},
    loathing: {label: 'Aversion', color: '#03a9f4'},
    disgust: {label: 'Dégoût', color: '#4caf50'},
    guilt: {label: 'Culpabilité', color: '#f9ce1d'},
    anger: {label: 'Colère', color: '#FF9800'},
    frustration: {label: 'Frustration', color: '#5C4742'},
    pride: {label: 'Fierté', color: '#2b908f'},
    love: {label: 'Amour', color: '#f9a3a4'},
    relief: {label: 'Soulagement', color: '#90ee7e'},
    joy: {label: 'Joie', color: '#69d2e7'},
    hope: {label: 'Espoir', color: '#fa4443'},
};

export const compareEmotions = (a, b) => {
    const c = new Intl.Collator();
    return c.compare(emotions[a]?.label, emotions[b]?.label);
};

export default emotions;
